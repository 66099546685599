<template>
    <div class="sprint_page_wrapper">
        <div class="sprint_full_body_wrapper">
            <sprintList showPageTitle />
        </div>
    </div>
</template>

<script>
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        sprintList: () => import('@apps/vue2TaskComponent/components/Sprint/List.vue'),
    },
    computed: {
        pageConfig() {
            return this.$route.meta?.pageConfig ? this.$route.meta.pageConfig : null
        }
    }
}
</script>

<style lang="scss" scoped>
.sprint_full_body_wrapper{
    height: 100%;
    .sprint_wrapper {
        padding: 15px;
    }
}
</style>